import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import ContactBlock from "../components/ContactBlock";

function Contact() {
  return (
    <div>
      <Header />

      <div className="w-full mt-20">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3190.983796266263!2d30.6920759!3d36.89073589999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14c39193589453f7%3A0x4f3b918f25d517af!2sLOC%20Group%20Bili%C5%9Fim%20A.%C5%9E!5e0!3m2!1str!2str!4v1732557613866!5m2!1str!2str"
          width="100%"
          height="450"
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>

      <ContactBlock />

      <Footer />
    </div>
  );
}

export default Contact;
