import React, { useEffect, useState } from "react";
import { FaBars } from "react-icons/fa6";

function Header() {
  const [urlPath, setUrlPath] = useState("");
  const [openSubMenu, setOpenSubMenu] = useState(false);
  const [openMobileMenu, setOpenMobileMenu] = useState(false);
  useEffect(() => {
    if (window.location.href.split("/")[3]) {
      setUrlPath(window.location.href.split("/")[3]);
    } else {
      setUrlPath("/");
    }
  });
  return (
    <div className="py-6 sticky top-0 z-10 bg-white w-full px-4 lg:px-0">
      <div className="mx-auto container">
        <div className="flex justify-between items-center">
          <div>
            <img
              src="/logo.webp"
              alt="adisyonet_logo"
              className="max-w-[200px]"
            />
          </div>
          <div className="hidden lg:flex lg:flex-col">
            <ul className="flex gap-x-6 poppins-regular text-[16px] text-[#19191C] ">
              <li>
                <a
                  href="/"
                  className={`${
                    urlPath == "/" && "text-primary poppins-medium"
                  }  hover:text-primary`}
                >
                  Ana Sayfa
                </a>
              </li>
              <li>
                <a
                  href="/hakkimizda"
                  className={`${
                    urlPath?.includes("hakkimizda") &&
                    "text-primary poppins-medium"
                  }  hover:text-primary`}
                >
                  Hakkımızda
                </a>
              </li>
              <li
                className="relative"
                onMouseEnter={() => {
                  setOpenSubMenu(true);
                }}
                onMouseLeave={() => {
                  setOpenSubMenu(false);
                }}
              >
                <a
                  href="#"
                  className={`${
                    urlPath == "cozumler" && "text-primary poppins-medium"
                  }  hover:text-primary `}
                >
                  Çözümler
                </a>

                <div
                  className={`absolute left-0  group-hover:block group-focus:block bg-white shadow-md rounded-lg w-48 pt-2 ${
                    !openSubMenu && "hidden"
                  }`}
                >
                  <ul className="flex flex-col">
                    <li>
                      <a
                        href="/cozumler/qr-menu-hizmeti"
                        className={`${
                          window?.location?.href
                            ?.split("/")[4]
                            ?.includes("qr-menu-hizmeti") &&
                          "text-primary poppins-medium"
                        } block poppins-regular px-4 py-2 text-black/90 hover:text-primary text-[15px]`}
                      >
                        QR Menü
                      </a>
                    </li>
                    <li>
                      <a
                        href="/cozumler/adisyon-yonetimi"
                        className={`${
                          window.location.href
                            ?.split("/")[4]
                            ?.includes("adisyon-yonetimi") &&
                          "text-primary poppins-medium"
                        } block poppins-regular px-4 py-2 text-black/90 hover:text-primary text-[15px]`}
                      >
                        Adisyon Yönetimi
                      </a>
                    </li>
                    <li>
                      <a
                        href="/cozumler/masa-yonetimi"
                        className={`${
                          window.location.href
                            ?.split("/")[4]
                            ?.includes("masa-yonetimi") &&
                          "text-primary poppins-medium"
                        } block poppins-regular px-4 py-2 text-black/90 hover:text-primary text-[15px]`}
                      >
                        Masa Yönetimi
                      </a>
                    </li>
                  </ul>
                </div>
              </li>
              <li>
                <a
                  href="/referanslar"
                  className={`${
                    urlPath?.includes("referanslar") &&
                    "text-primary poppins-medium"
                  }  hover:text-primary`}
                >
                  Referanslar
                </a>
              </li>
              <li>
                <a
                  href="/iletisim"
                  className={`${
                    urlPath == "iletisim" && "text-primary poppins-medium"
                  }  hover:text-primary`}
                >
                  İletişim
                </a>
              </li>
            </ul>
          </div>

          <div className="hidden lg:flex lg:flex-col">
            <a href="/iletisim">
              <button className="poppins-semibold text-white text-[14px] border-[1px] bg-primary p-3 rounded-[15px]">
                Fiyat Teklifi Al
              </button>
            </a>
          </div>

          <button
            onClick={() => {
              setOpenMobileMenu(!openMobileMenu);
            }}
            className="flex flex-col lg:hidden"
          >
            <FaBars className="text-black/80 text-[22px]" />
          </button>
        </div>
      </div>

      {openMobileMenu && (
        <div className="w-full bg-white absolute top-16 p-4 left-0">
          <ul className="gap-y-2 flex flex-col">
            <li>
              <a
                href="/"
                className={`${
                  urlPath == "/" && "text-primary poppins-medium"
                }  hover:text-primary`}
              >
                Ana Sayfa
              </a>
            </li>
            <li>
              <a
                href="/hakkimizda"
                className={`${
                  urlPath?.includes("hakkimizda") &&
                  "text-primary poppins-medium"
                }  hover:text-primary`}
              >
                Hakkımızda
              </a>
            </li>

            <li>
              <a
                href="/cozumler/qr-menu-hizmeti"
                className={`${
                  urlPath?.includes("qr-menu-hizmeti") &&
                  "text-primary poppins-medium"
                }  hover:text-primary`}
              >
                QR Menü Çözümümü
              </a>
            </li>

            <li>
              <a
                href="/cozumler/adisyon-yonetimi"
                className={`${
                  urlPath?.includes("adisyon-yonetimi") &&
                  "text-primary poppins-medium"
                }  hover:text-primary`}
              >
                Adisyon Yönetimi
              </a>
            </li>

            <li>
              <a
                href="/cozumler/masa-yonetimi"
                className={`${
                  urlPath?.includes("masa-yonetimi") &&
                  "text-primary poppins-medium"
                }  hover:text-primary`}
              >
                Masa Yönetimi
              </a>
            </li>

            <li>
              <a
                href="/referanslar"
                className={`${
                  urlPath?.includes("referanslar") &&
                  "text-primary poppins-medium"
                }  hover:text-primary`}
              >
                Referanslar
              </a>
            </li>

            <li>
              <a
                href="/iletisim"
                className={`${
                  urlPath?.includes("iletisim") && "text-primary poppins-medium"
                }  hover:text-primary`}
              >
                İletişim
              </a>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
}

export default Header;
