import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";

const refList = [
  {
    id: 0,
    name: "salt-vegan-vegetarian",
    img: "/ref/salt.webp",
  },
  {
    id: 1,
    name: "mono",
    img: "/ref/mono.webp",
  },
  {
    id: 3,
    name: "fikdikici-firin-kafe",
    img: "/ref/findikici.webp",
  },
  {
    id: 4,
    name: "hüner-mutfak",
    img: "/ref/huner.webp",
  },
];

function Referances() {
  return (
    <div>
      <Header />

      <div className="mx-auto container">
        <div className="text-center mt-8">
          <h1 className="poppins-bold text-[40px]">Referanslar</h1>
          <p className="text-[17px] poppins-regular text-black/70">
            Çözümlerimizi kullanan iş ortaklarımız
          </p>
        </div>

        <div className="grid grid-cols-1 px-4 lg:grid-cols-4 gap-x-4 gap-y-4 mt-8">
          {refList.map((item, index) => (
            <div
              key={index}
              className="h-[200px] border-[1px] border-black/5 rounded-lg bg-black/90 px-4 flex items-center justify-center"
            >
              <img
                src={item?.img}
                className="h-[50px] w-auto"
                alt={item?.name}
              />
            </div>
          ))}
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Referances;
