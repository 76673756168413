import React from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import ContactBlock from "../../components/ContactBlock";

function AdisyonYonetimi() {
  return (
    <div>
      <Header />

      <div className="container mx-auto">
        <div className="text-center mt-8">
          <h1 className="poppins-bold text-[40px]">Adisyon Yönetimi</h1>
          <p className="text-[17px] poppins-regular text-black/70">
            İşletmenin ihtiyaç duyabileceği adisyon yonetimi hizmeti jet
            hızında!
          </p>
        </div>
      </div>

      <div>
        <div className="container mx-auto flex flex-col lg:flex-row gap-x-8 mt-8 lg:mt-24 items-center">
          <div className="hidden lg:flex lg:flex-col">
            <img
              src="/cozumler/siparis-yonetimi.webp"
              className="min-w-[800px] w-[800px]"
              alt="siparis-yonetimi"
            />
          </div>
          <div>
            <div className="flex flex-col lg:hidden">
              <img
                src="/cozumler/siparis-yonetimi.webp"
                className="min-w-[400px] w-[400px]"
                alt="siparis-yonetimi"
              />
            </div>

            <h6 className="poppins-medium text-[#19191C] text-[28px] text-center lg:text-left">
              Sipariş ve Adisyon Yönetimi
            </h6>

            <div>
              <ul className="max-w-[700px] mt-4 poppins-regular text-black/80 space-y-4 list-disc list-inside">
                <li>
                  Yönetim panelinizden seçtiğiniz masanın adisyonunu
                  görüntüleyebilirsiniz.
                </li>
                <li>
                  Yönetim panelinizden seçtiğiniz masaya kolaylıkla ürün
                  ekleyebilir, çıkarabilir veya silebilirsiniz.
                </li>
                <li>
                  Yönetim panelinizden eklemek istediğiniz ürünün kategorisi ile
                  filtreleme yaparak çok kolay bir şekilde aradığınız ürüne
                  erişebilirsiniz.
                </li>
                <li>
                  Yönetim panelinizden müşterinin adisyonunu kapatabilirsiniz ve
                  ödeme yöntemini nakit veya kredi kartı olarak
                  işaretleyebilirsiniz.
                </li>
                <li>
                  Yönetim panelinizde ana sayfada son 24 saat'te aldığınız
                  ödemelerin nakit ve kredi kartı olarak sayısal değerini anlık
                  takip edebilirsiniz.
                </li>
              </ul>

              <div className="mt-4 justify-center flex">
                <a
                  target="_blank"
                  href="https://qr-menu.adisyonjet.com/jetcoffee/table-1"
                  className="poppins-semibold text-[14px] text-white bg-primary p-4 rounded-[15px]"
                >
                  Yönetim Panelimizi İnceleyin
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ContactBlock />

      <Footer />
    </div>
  );
}

export default AdisyonYonetimi;
