import React from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import ContactBlock from "../../components/ContactBlock";

function SiparisYonetimi() {
  return (
    <div>
      <Header />

      <div className="container mx-auto">
        <div className="text-center mt-8">
          <h1 className="poppins-bold text-[40px]">Masa Yönetimi</h1>
          <p className="text-[17px] poppins-regular text-black/70">
            İşletmenin ihtiyaç duyabileceği masa yonetimi hizmeti jet hızında!
          </p>
        </div>
      </div>

      <div>
        <div className="container mx-auto flex flex-col lg:flex-row gap-x-8 mt-8 lg:mt-24 items-center">
          <div>
            <img
              src="/cozumler/masa-yonetimi.webp"
              className="lg:w-[600px] h-auto"
              alt="masa-yonetimi"
            />
          </div>
          <div>
            <h6 className="poppins-medium text-[#19191C] text-[28px] text-center lg:text-left">
              Salon ve Masa Yönetimi
            </h6>

            <div>
              <ul className="max-w-[700px] mt-4 poppins-regular text-black/80 space-y-4 list-disc list-inside">
                <li>
                  Yönetim paneli üzerinden dilediğiniz kadar salon ve masa
                  oluşturabilirsiniz.
                </li>
                <li>
                  Masaların durumlarını görüntüleyebilirsiniz. Masa durumları
                  Dolu ve Boş olarak görüntüleyebilirsiniz.
                </li>
                <li>
                  Müşterileriniz için açılan masaların açılış saatini
                  görüntüleyebilirsiniz.
                </li>
              </ul>

              <div className="mt-4 justify-center flex">
                <a
                  target="_blank"
                  href="https://qr-menu.adisyonjet.com/jetcoffee/table-1"
                  className="poppins-semibold text-[14px] text-white bg-primary p-4 rounded-[15px]"
                >
                  Yönetim Panelimizi İnceleyin
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ContactBlock />

      <Footer />
    </div>
  );
}

export default SiparisYonetimi;
