import React from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import ContactBlock from "../../components/ContactBlock";

function QRMenu() {
  return (
    <div>
      <Header />

      <div className="container mx-auto">
        <div className="text-center mt-8">
          <h1 className="poppins-bold text-[40px]">QR Menü Hizmeti</h1>
          <p className="text-[17px] poppins-regular text-black/70">
            İşletmenin ihtiyaç duyabileceği qr menü hizmeti jet hızında!
          </p>
        </div>
      </div>

      <div>
        <div className="container mx-auto flex flex-col lg:flex-row gap-x-8 mt-8 lg:mt-24 items-center">
          <div>
            <img
              src="/cozumler/qr-menu.webp"
              className="min-w-[300px] w-[300px]"
              alt="qr-menu"
            />
          </div>
          <div>
            <h6 className="poppins-medium text-[#19191C] text-[28px] text-center lg:text-left">
              QR Menü
            </h6>

            <div>
              <ul className="max-w-[700px] mt-4 poppins-regular text-black/80 space-y-4 list-disc list-inside">
                <li>
                  Dijital QR menü ile müşterileriniz akıllı telefonunun kamerası
                  ile hızlı bir şekilde dijital menünüze erişebilir.
                </li>
                <li>
                  Dijital menünüz aracılığı ile müşteriler eklediğiniz ürünün
                  görselini, fiyatını, açıklamasını ve görselini
                  görüntüleyebilir.
                </li>
                <li>
                  Dijital menünüz aracılığı ile müşteriler bulunduğu masadan
                  garson çağırıp adisyonunu isteyebilir. Bu sayede hızlı ve
                  kolay bir şekilde müşteriler işletmeniz ile etkileşime
                  geçebilir.
                </li>
                <li>
                  Dijital menünüz aracılığı ile maaliyetleri en düşüğe indirin.
                  Fiyat değişikliği veya ürün değişikliklerinde basım
                  maaliyetleri ile uğraşayın. Yönetim panelinizden saniyeler
                  içerisinde menünüzü düzenleyebilirsiniz.
                </li>
                <li>
                  Dijital menünüze istediğiniz yabancı dili ekleyebilirsiniz
                  menünüzün ismini ve açıklaması için istediğiniz dilde çeviri
                  saniyeler içerisinde ekleyebilirsiniz.
                </li>
              </ul>

              <div className="mt-4 justify-center flex">
                <a
                  target="_blank"
                  href="https://qr-menu.adisyonjet.com/jetcoffee/table-1"
                  className="poppins-semibold text-[14px] text-white bg-primary p-4 rounded-[15px]"
                >
                  QR Menümüzü İnceleyin
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="container ml-auto flex flex-col lg:flex-row gap-x-8 mt-8 lg:mt-24 items-center">
          <div>
            <img
              src="/cozumler/yonetim-paneli.webp"
              className="min-w-[300px] w-[800px] flex flex-col lg:hidden"
            />
          </div>
          <div>
            <h6 className="poppins-medium text-[#19191C] text-center lg:text-start text-[28px]">
              Yönetim Panelimiz
            </h6>

            <div>
              <ul className="max-w-[700px] mt-4 poppins-regular text-black/80 space-y-4 list-disc list-inside">
                <li>
                  Yönetim panelinizden QR Menünüze kategori ve alt kategori
                  ekleyebilirsiniz.
                </li>
                <li>
                  Yönetim panelinizden QR Menünüze yeni ürün ekleyip istediğiniz
                  zaman saniyeler içerisinde görsel, fiyat, isim ve açıklamasını
                  düzenleyebilirsiniz.
                </li>
                <li>
                  Yönetim panelinizden QR Menünüzdeki ürünlerin açıklamalarını
                  ve isimlerini istediğiniz dile saniyeler içerisinde çevirisini
                  ekleyebilirsiniz.
                </li>
                <li>
                  Yönetim panelinizden QR Menünüzdeki ürünlerden dilediğinizi
                  saniyeler içerisinde kaldırabilirsiniz.
                </li>
                <li>
                  Yönetim panelinizden QR Menünüzden gelen garson çağır ve
                  adisyon iste bildirimlerini takip edebilirsiniz. Dilerseniz
                  panel üzerinden SMS yöntemini aktif ederek bildirimlerinizi
                  SMS ile alabilirsiniz.
                </li>
              </ul>

              <div className="mt-4 justify-center flex">
                <a
                  target="_blank"
                  href="https://qr-menu.adisyonjet.com/jetcoffee/table-1"
                  className="poppins-semibold text-[14px] text-white bg-primary p-4 rounded-[15px]"
                >
                  Yönetim Panelimizi İnceleyin
                </a>
              </div>
            </div>
          </div>

          <div>
            <img
              src="/cozumler/yonetim-paneli.webp"
              className="min-w-[300px] w-[800px] hidden lg:flex lg:flex-col"
              alt="yonetim-paneli"
            />
          </div>
        </div>
      </div>

      <ContactBlock />

      <Footer />
    </div>
  );
}

export default QRMenu;
