import React from "react";
import { FaFacebook, FaInstagram, FaTiktok, FaTwitter } from "react-icons/fa6";
function Footer() {
  return (
    <footer className="mt-28 ">
      <div className="bg-black/90 w-full py-16">
        <div className="container mx-auto">
          <div className="grid grid-cols-1 lg:grid-cols-4 gap-6 px-4">
            <div className="flex flex-col gap-y-4">
              <img
                className="w-[200px]"
                src="/logo-white.webp"
                alt="logo-white"
              />
              <p className="text-white/90 poppins-regular text-[14px]">
                Adisyonjet ile işletmeniz için QR menü, sipariş yönetimi ve
                adisyon yönetimi gibi birçok ihtiyacınıza çözüm sunuyoruz.
              </p>
            </div>
            <div className="flex flex-col gap-x-4 lg:mx-auto">
              <h6 className="poppins-medium text-white text-[21px] mb-4">
                Hızlı Bağlantılar
              </h6>
              <ul className="text-white poppins-regular flex flex-col gap-y-2">
                <li>
                  <a href="/">Ana Sayfa</a>
                </li>
                <li>
                  <a href="/hakkimizda">Hakkımızda</a>
                </li>
                <li>
                  <a href="/cozumler/qr-menu-hizmeti">QR Menü Çözümü</a>
                </li>
                <li>
                  <a href="/cozumler/adisyon-yonetimi">
                    Adisyon Yonetimi Çözümü
                  </a>
                </li>
                <li>
                  <a href="/cozumler/masa-yonetimi">Masa Yonetimi Çözümü</a>
                </li>
                <li>
                  <a href="/referanslar">Referanslar</a>
                </li>
                <li>
                  <a href="/iletisim">İletişim</a>
                </li>
              </ul>
            </div>
            <div className="flex flex-col gap-x-4 mx-auto">
              <h6 className="poppins-medium text-white text-[21px] mb-4">
                Son Yazılarımız
              </h6>
              <ul className="text-white poppins-regular flex flex-col gap-y-2">
                <li>
                  <a href="/">QR Menünün İşletmelere Katkıları</a>
                </li>
                <li>
                  <a href="/">
                    Adisyon Otomasyonu ile İşletmenizi Sonraki Seviyeye Taşıyın
                  </a>
                </li>
                <li>
                  <a href="/">
                    QR Menülerde Çeviri Desteği: Uluslararası Müşterilere Nasıl
                    Kolaylık Sağlanır?
                  </a>
                </li>
                <li>
                  <a href="/">
                    Adisyon Yönetimi ile Restoranlarda Sipariş Takibini
                    Kolaylaştırın
                  </a>
                </li>
                <li>
                  <a href="/">
                    QR Menü ile Restoranlarda Müşteri Deneyimini Nasıl
                    İyileştirirsiniz?
                  </a>
                </li>
              </ul>
            </div>
            <div className="flex flex-col gap-x-4 lg:mx-auto">
              <h6 className="poppins-medium text-white text-[21px] mb-4">
                Sosyal Medya
              </h6>
              <ul className="text-white poppins-regular flex flex-row gap-x-4 lg:gap-x-0 lg:flex-col gap-y-4 lg:items-center">
                <li>
                  <a
                    href="https://www.instagram.com/adisyonjet"
                    target="_blank"
                  >
                    <FaInstagram className="text-primary text-[36px] rounded-full bg-white p-2" />
                  </a>
                </li>
                <li>
                  <a href="#" target="_blank">
                    <FaTwitter className="text-primary text-[36px] rounded-full bg-white p-2" />
                  </a>
                </li>
                <li>
                  <a href="#" target="_blank">
                    <FaFacebook className="text-primary text-[36px] rounded-full bg-white p-2" />
                  </a>
                </li>
                <li>
                  <a href="#" target="_blank">
                    <FaTiktok className="text-primary text-[36px] rounded-full bg-white p-2" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full bg-black/80  flex flex-col items-center text-white py-2">
        <p>
          <a href="https://adisyonjet.com">Adisyonjet</a> © Tüm Hakları
          Saklıdır. 2024
        </p>
      </div>
    </footer>
  );
}

export default Footer;
