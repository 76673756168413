import React, { useState } from "react";
import { FaAt, FaPhone } from "react-icons/fa6";
import { FaMapPin } from "react-icons/fa";

function ContactBlock() {
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [mailAddress, setMailAddress] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [subject, setSubject] = useState("");
  const [prefTime, setPrefTime] = useState("");
  return (
    <div className="mt-20 container mx-auto">
      <div className="flex flex-col lg:flex-row justify-between px-4">
        <div>
          <h2 className="poppins-medium text-[#19191C] text-[48px] text-center lg:text-left lg:text-[64px]">
            İletişim'e Geç
          </h2>

          <div className="mt-6 flex flex-col gap-y-6">
            <div className="flex gap-x-2 items-center">
              <div>
                <FaMapPin className="text-black/70 text-[24px]" />
              </div>
              <div>
                <a
                  target="_blank"
                  href="https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwjB1M2S9PeJAxWLTqQEHSpoN-QQ4kB6BAgtEAM&url=%2Fmaps%2Fplace%2F%2Fdata%3D!4m2!3m1!1s0x14c39193589453f7%3A0x4f3b918f25d517af%3Fsa%3DX%26ved%3D1t%3A8290%26ictx%3D111&usg=AOvVaw0kRyoZvIoO-3592vK-5cBp&opi=89978449"
                  className="poppins-regular text-black/80 text-[15px]"
                >
                  Altındağ Mh 100.Yıl Bulvarı No:12 CityPlaza, Kat:5 D:23, 07000
                  Muratpaşa/Antalya
                </a>
              </div>
            </div>
            <div className="flex gap-x-2 items-center">
              <div>
                <FaPhone className="text-black/70 text-[24px]" />
              </div>
              <div>
                <a
                  target="_blank"
                  href="tel:902429222022"
                  className="poppins-regular text-black/80 text-[15px]"
                >
                  (0242) 922 20 22
                </a>
              </div>
            </div>

            <div className="flex gap-x-2 items-center">
              <div>
                <FaAt className="text-black/70 text-[24px]" />
              </div>
              <div>
                <a
                  target="_blank"
                  href="mailto:satis@adisyonjet.com"
                  className="poppins-regular text-black/80 text-[15px]"
                >
                  satis@adisyonjet.com
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="p-8 border-[1px] border-black/5 rounded-lg bg-white w-full mt-8 lg:mt-0 lg:w-6/12 flex flex-col gap-y-6">
          <div className="flex gap-y-1 flex-col">
            <h6 className="poppins-medium text-[28px] text-center">
              Bilgi İletişim Formu
            </h6>

            <p className="poppins-regular text-black/70 text-center">
              İletişim formunu doldurarak satış birimimizden hızlı bir şekilde
              bilgi alabilirsiniz.
            </p>
          </div>

          <div className="flex justify-between gap-x-4">
            <input
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="w-6/12 p-2 px-4 rounded-lg bg-gray-50 poppins-regular"
              placeholder="Adınız"
            />
            <input
              value={surname}
              onChange={(e) => setSurname(e.target.value)}
              className="w-6/12 p-2 px-4 rounded-lg bg-gray-50 poppins-regular"
              placeholder="Soyadınız"
            />
          </div>

          <div className="flex justify-between gap-x-4">
            <input
              value={mailAddress}
              onChange={(e) => setMailAddress(e.target.value)}
              className="w-full p-2 px-4 rounded-lg bg-gray-50 poppins-regular"
              placeholder="Mail Adresiniz"
            />
          </div>

          <div className="flex justify-between gap-x-4">
            <input
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              className="w-full p-2 px-4 rounded-lg bg-gray-50 poppins-regular"
              placeholder="Telefon Numaranız"
            />
          </div>

          <div className="flex justify-between gap-x-4">
            <input
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
              className="w-full p-2 px-4 rounded-lg bg-gray-50 poppins-regular"
              placeholder="Konu"
            />
          </div>

          <div className="flex justify-between gap-x-4">
            <select className="w-full p-2 px-4 rounded-lg bg-gray-50 poppins-regular text-black/90">
              <option value="none">Aranmayı Tercih Ettiğiniz Saat</option>
              <option value="none">Tercihim Yok</option>
              <option>09:00-10:00</option>
              <option>10:00-11:00</option>
              <option>11:00-12:00</option>
              <option>12:00-13:00</option>
              <option>13:00-14:00</option>
              <option>14:00-15:00</option>
              <option>15:00-16:00</option>
              <option>17:00-18:00</option>
            </select>
          </div>

          <div>
            <button
              target="_blank"
              href="https://qr-menu.adisyonjet.com/jetcoffee/table-1"
              className="poppins-semibold text-[16px] text-white bg-primary py-3 w-full rounded-[15px] "
            >
              Formu Gönder
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactBlock;
