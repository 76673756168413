import React from "react";
import "./App.css";
import Home from "./screens/Home";
import { BrowserRouter, Routes, Route } from "react-router";
import Contact from "./screens/Contact";
import Referances from "./screens/Referances";
import About from "./screens/About";
import NotFound from "./screens/NotFound";
import QRMenu from "./screens/cozumler/QRMenu";
import AdisyonYonetimi from "./screens/cozumler/AdisyonYonetimi";
import SiparisYonetimi from "./screens/cozumler/SiparisYonetimi";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" index element={<Home />} />
        <Route path="/hakkimizda" index element={<About />} />
        <Route path="/cozumler/qr-menu-hizmeti" index element={<QRMenu />} />
        <Route
          path="/cozumler/adisyon-yonetimi"
          index
          element={<AdisyonYonetimi />}
        />
        <Route
          path="/cozumler/masa-yonetimi"
          index
          element={<SiparisYonetimi />}
        />
        <Route path="/referanslar" index element={<Referances />} />
        <Route path="/iletisim" element={<Contact />} />
        <Route path="*" element={<Home />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
