import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { FaBullseye, FaEye } from "react-icons/fa6";

function About() {
  return (
    <div>
      <Header />

      <div className="mx-auto container">
        <div className="text-center mt-8">
          <h1 className="poppins-bold text-[40px]">Hakkımızda</h1>
          <p className="text-[17px] poppins-regular text-black/70">
            İşletmenin ihtiyaç duyduğu çözümler jet hızında
          </p>
        </div>

        <div className="mt-8 flex flex-col lg:flex-row justify-between px-4 mb-12 lg:mb-0">
          <div className="w-full lg:w-6/12">
            <h2 className="text-[28px] lg:text-[36px] poppins-semibold">
              Biz Kimiz?
            </h2>
            <p className="w-full lg:w-9/12 poppins-regular text-[16px] lg:text-[18px] text-black/70 mt-2">
              <b>Adisyonjet</b>, Antalya merkezli <b>Loc Group Bilişim A.Ş.</b>{" "}
              şirketinin bir markası olarak, restoranlar ve diğer hizmet sektörü
              işletmelerine dijital çözümler sunmaktadır. Müşterilerimize,
              teknolojiyi kullanarak işletmelerinin verimliliğini artırma ve
              müşteri deneyimini iyileştirme imkanı tanıyoruz.
            </p>

            <p className="w-full lg:w-9/12 poppins-regular text-[16px] lg:text-[18px] text-black/70 mt-4">
              <b>Adisyonjet</b> olarak amacımız, işletmelerin dijital dönüşüm
              süreçlerini en hızlı ve en verimli şekilde gerçekleştirmelerine
              yardımcı olmaktır. Geliştirdiğimiz yenilikçi teknolojiler ile
              restoranların operasyonlarını daha pratik ve verimli bir hale
              getirmek, sektördeki tüm oyunculara değer katmak için çalışıyoruz.
            </p>
          </div>
          <div className="hidden lg:flex lg:flex-col w-6/12">
            <img
              src="/hakkimizda/hakkimizda-1.webp"
              alt="hakkimizda-1"
              className="w-11/12 h-auto rounded-lg"
            />
          </div>
        </div>
      </div>

      <div className=" bg-[#19191C] lg:py-9 py-4 relative mt-0">
        <div className="container mx-auto flex flex-col gap-y-8 lg:flex-row justify-between items-center gap-x-10">
          <p className="text-[#C2C2C2] hidden lg:flex lg:flex-col poppins-medium text-[18px]">
            Çözümlerimizi kullanan <br />
            iş ortaklarımız
          </p>

          <p className="text-[#C2C2C2] lg:hidden poppins-medium text-[18px]">
            Çözümlerimizi kullanan iş ortaklarımız
          </p>

          <img
            src="/ref/salt.webp"
            className="max-w-[210px] lg:max-w-[120px]"
            alt="salt"
          />
          <img
            src="/ref/mono.webp"
            className="max-w-[210px] lg:max-w-[120px]"
            alt="mono"
          />
        
          <img
            src="/ref/findikici.webp"
            className="max-w-[210px] lg:max-w-[160px]"
            alt="findikici"
          />
          <img
            src="/ref/huner.webp"
            className="max-w-[140px] lg:max-w-[90px] "
            alt="huner"
          />
        </div>
      </div>

      <div className="mx-auto container mt-8">
        <h2 className="text-[36px] poppins-semibold mb-8 text-center lg:text-left">
          Hizmetlerimiz
        </h2>

        <div className="flex flex-col lg:flex-row  lg:justify-between gap-x-4">
          <div className="w-full lg:w-5/12 flex flex-col justify-center items-center">
            <img
              src="/cozumler/qr-menu.webp"
              className="w-[280px] lg:w-[350px]"
              alt="tablet"
            />
          </div>
          <div className="flex flex-col gap-y-6 mt-2 w-full px-4 lg:px-0 lg:w-7/12">
            <div>
              <h6 className="text-[24px] poppins-medium">1. QR Menü Hizmeti</h6>
              <ul className="gap-6 space-y-2 mt-2 w-full poppins-regular text-[16px]">
                <li>
                  <b>Çoklu Dil Seçeneği:</b> Müşterileriniz, menüye farklı
                  dillerde kolayca erişebilir. Bu özellik, yerli ve yabancı
                  müşterileriniz için kusursuz bir deneyim sunar.
                </li>
                <li>
                  <b>Anlık Panel Yönetimi:</b> Restoran sahipleri veya
                  yöneticileri, menüyü anlık olarak güncelleyebilir. Yeni ürün
                  ekleme, mevcut ürünlerde değişiklik yapma veya ürün çıkarma
                  işlemleri çok kolay bir şekilde yapılabilir.
                </li>
                <li>
                  <b>Hızlı ve Hijyenik Menü Kullanımı:</b> QR kodu ile dijital
                  menüye erişim sağlayan müşteriler, fiziksel menülere
                  dokunmadan sipariş verebilir. Bu da hijyen ve hız açısından
                  büyük avantaj sağlar.
                </li>
              </ul>
            </div>

            <div>
              <h6 className="text-[24px] poppins-medium">
                2. Adisyon Yönetim Hizmeti
              </h6>
              <ul className="gap-6 space-y-2 mt-2 w-full poppins-regular text-[16px]">
                <li>
                  <b>Dinamik Adisyon Yönetimi:</b> Müşterinin masasına eklenen
                  tüm ürünler, anlık olarak görülebilir. Menüdeki ürünler
                  kolayca eklenip, çıkarılabilir veya düzenlenebilir.
                </li>
                <li>
                  <b>Ödeme Seçenekleri:</b> Müşteri, ödemesini kredi kartı veya
                  nakit ile gerçekleştirebilir. Ödeme türü seçildikten sonra,
                  restoran sahipleri ve yöneticileri anlık olarak ödeme
                  bilgilerini görüntüleyebilir.
                </li>
                <li>
                  <b>Son 24 Saatlik Ödeme Takibi:</b> Ana panel üzerinden, son
                  24 saatte yapılan tüm kredi kartı ve nakit ödemeleri takip
                  edilebilir. Bu sayede, ödeme süreçleri kolayca izlenebilir ve
                  raporlanabilir.
                </li>
              </ul>
            </div>

            <div>
              <h6 className="text-[24px] poppins-medium">
                3. Salon ve Masa Yönetimi
              </h6>
              <ul className="gap-6 space-y-2 mt-2 w-full poppins-regular text-[16px]">
                <li>
                  <b>Masa Yönetimi:</b> Müşteriler için yeni masalar açılabilir
                  ve her masa için farklı durumlar belirlenebilir (boş, dolu,
                  rezerve edilmiş).
                </li>
                <li>
                  <b>Anlık Masa Durumu:</b> Hangi masanın dolu, hangi masanın
                  boş olduğu anlık olarak izlenebilir. Bu, personel için
                  kolaylık sağlar ve verimli bir masa yönetimi sunar.
                </li>
                <li>
                  <b>Masa Açılış Saati:</b> Her açılan masanın saat kaçta
                  açıldığı görüntülenebilir. Bu özellik, servis süreçlerini
                  optimize etmek ve zaman yönetimi sağlamak için önemlidir.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-12 w-full bg-black/90 py-16 text-center">
        <h2 className=" poppins-bold text-white text-[48px] lg:text-[62px] ">
          Aklınıza birşey mi takıldı ?
        </h2>
        <p className="poppins-regular text-white/80 text-[18px] lg:text-[24px] mt-2 ">
          Aklınıza takılan sorular için iletişim formumuzu doldurarak satış
          ekibimiz tarafından bilgi alabilirsiniz.
        </p>

        <a href="/iletisim">
          <button className="poppins-semibold text-[14px] text-white border-white border-2 p-4 rounded-[15px] mt-7">
            Hemen Bilgi Al
          </button>
        </a>
      </div>

      <div className="container mx-auto mt-8 px-4 lg:px-0">
        <h2 className="text-center text-[28px] lg:text-[36px] poppins-semibold">
          Misyonumuz & Vizyonumuz
        </h2>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-y-4 mt-8 gap-x-4">
          <div className="p-4 pb-8 rounded-lg border-[1px] border-black/5 flex flex-col items-center gap-y-2">
            <div className="mt-4 bg-black/90 p-2 rounded-full w-fit">
              <FaBullseye className="text-[32px] text-white" />
            </div>

            <div>
              <h6 className="text-[20px] poppins-medium">Misyonumuz</h6>
            </div>

            <div>
              <p className="text-[14px] poppins-regular text-black/70">
                Adisyonjet olarak, işletmelere dijital çözümler sunarak, QR
                menü, sipariş takip, adisyon yönetimi ve masa planlaması gibi
                hizmetlerle verimlilik sağlıyoruz. Amacımız, müşteri deneyimini
                iyileştirirken işletmelere operasyonel kolaylık ve esneklik
                sunmak, dijitalleşme sürecinde onlara liderlik etmektir.
              </p>
            </div>
          </div>
          <div className="p-4 pb-8 rounded-lg border-[1px] border-black/5 flex flex-col items-center gap-y-2">
            <div className="mt-4 bg-black/90 p-2 rounded-full w-fit">
              <FaEye className="text-[32px] text-white" />
            </div>

            <div>
              <h6 className="text-[20px] poppins-medium">Vizyonumuz</h6>
            </div>

            <div>
              <p className="text-[14px] poppins-regular text-black/70">
                Vizyonumuz, işletmeleri dijital dünyaya taşımak, verimliliği
                artırarak müşteri memnuniyetini en üst düzeye çıkarmaktır.
                Yenilikçi çözümlerimizle sektördeki liderliğimizi sürdürmek ve
                dünya çapında tanınan bir marka olmak hedefindeyiz.
              </p>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default About;
