import React, { useState } from "react";
import Header from "../components/Header";
import {
  FaAt,
  FaFacebook,
  FaInstagram,
  FaMapLocationDot,
  FaPhone,
  FaTiktok,
  FaTwitter,
} from "react-icons/fa6";
import { FaMapPin } from "react-icons/fa";
import Footer from "../components/Footer";
import ContactBlock from "../components/ContactBlock";
function Home() {
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [mailAddress, setMailAddress] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [subject, setSubject] = useState("");
  const [prefTime, setPrefTime] = useState("");
  return (
    <div>
      <Header />

      <div className="mt-12 lg:mt-20 flex flex-col justify-center items-center">
        <h2 className="text-center poppins-semibold lg:poppins-medium text-[40px]  lg:text-[72px] text-[#19191C]">
          İşletmenin ihtiyaç duyduğu <br />
          çözümler jet hızında
        </h2>
        <p className="text-center poppins-medium text-[#828487] text-[16px] lg:text-[18px] mt-6">
          İşletmeniz için ihtiyaç duyacağınız qr menü hizmetleri adisyon
          otomasyonu ve çok daha fazlasına jet hızında ulaşın.
        </p>

        <button className="poppins-semibold text-[14px] text-white bg-primary p-4 rounded-[15px] mt-7">
          Demo'yu görüntüle
        </button>
      </div>

      <div className="justify-center items-center mt-8 relative hidden lg:flex">
        <div className="relative left-24">
          <img
            src="tablet2.webp"
            className="max-w-[700px] h-auto"
            alt="tablet2"
          />
        </div>
        <div className="relative -left-24">
          <img
            src="macbook1.webp"
            className="max-w-[1100px] h-auto"
            alt="tablet1"
          />
        </div>
      </div>

      <div className="w-full bg-[#19191C] lg:py-9 py-4 relative mt-8 lg:mt-0 lg:-top-28">
        <div className="container mx-auto flex flex-col gap-y-8 lg:flex-row justify-between items-center gap-x-10">
          <p className="text-[#C2C2C2] hidden lg:flex lg:flex-col poppins-medium text-[18px]">
            Çözümlerimizi kullanan <br />
            iş ortaklarımız
          </p>

          <p className="text-[#C2C2C2] lg:hidden poppins-medium text-[18px]">
            Çözümlerimizi kullanan iş ortaklarımız
          </p>

          <img
            src="ref/salt.webp"
            className="max-w-[210px] lg:max-w-[120px]"
            alt="salt"
          />
          <img
            src="ref/mono.webp"
            className="max-w-[210px] lg:max-w-[120px]"
            alt="mono"
          />
          <img
            src="ref/findikici.webp"
            className="max-w-[210px] lg:max-w-[160px]"
            alt="findikici"
          />
          <img
            src="ref/huner.webp"
            className="max-w-[140px] lg:max-w-[90px] "
            alt="huner"
          />
        </div>
      </div>

      <div>
        <div>
          <h2 className="text-center poppins-medium text-[#19191C] text-[48px] mt-8 lg:mt-0 lg:text-[64px]">
            Çözümlerimiz
          </h2>
        </div>

        <div className="container mx-auto flex flex-col lg:flex-row gap-x-8 mt-8 lg:mt-24 items-center">
          <div>
            <img
              src="/cozumler/qr-menu.webp"
              className="min-w-[300px] w-[300px]"
              alt="qr-menu"
            />
          </div>
          <div>
            <h6 className="poppins-medium text-[#19191C] text-[28px] text-center lg:text-left">
              QR Menü Çözümümüz
            </h6>

            <div>
              <ul className="max-w-[700px] mt-4 poppins-regular text-black/80 space-y-4 list-disc list-inside">
                <li>
                  Dijital QR menü ile müşterileriniz akıllı telefonunun kamerası
                  ile hızlı bir şekilde dijital menünüze erişebilir.
                </li>
                <li>
                  Dijital menünüz aracılığı ile müşteriler eklediğiniz ürünün
                  görselini, fiyatını, açıklamasını ve görselini
                  görüntüleyebilir.
                </li>
                <li>
                  Dijital menünüz aracılığı ile müşteriler bulunduğu masadan
                  garson çağırıp adisyonunu isteyebilir. Bu sayede hızlı ve
                  kolay bir şekilde müşteriler işletmeniz ile etkileşime
                  geçebilir.
                </li>
                <li>
                  Dijital menünüz aracılığı ile maaliyetleri en düşüğe indirin.
                  Fiyat değişikliği veya ürün değişikliklerinde basım
                  maaliyetleri ile uğraşayın. Yönetim panelinizden saniyeler
                  içerisinde menünüzü düzenleyebilirsiniz.
                </li>
                <li>
                  Dijital menünüze istediğiniz yabancı dili ekleyebilirsiniz
                  menünüzün ismini ve açıklaması için istediğiniz dilde çeviri
                  saniyeler içerisinde ekleyebilirsiniz.
                </li>
              </ul>

              <div className="mt-4 justify-center flex">
                <a
                  target="_blank"
                  href="https://qr-menu.adisyonjet.com/jetcoffee/table-1"
                  className="poppins-semibold text-[14px] text-white bg-primary p-4 rounded-[15px]"
                >
                  QR Menümüzü İnceleyin
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="container ml-auto flex flex-col lg:flex-row gap-x-8 mt-8 lg:mt-24 items-center">
          <div>
            <img
              src="/cozumler/yonetim-paneli.webp"
              className="min-w-[300px] w-[800px] flex flex-col lg:hidden"
            />
          </div>
          <div>
            <h6 className="poppins-medium text-[#19191C] text-center lg:text-start text-[28px]">
              Yönetim Panelimiz
            </h6>

            <div>
              <ul className="max-w-[700px] mt-4 poppins-regular text-black/80 space-y-4 list-disc list-inside">
                <li>
                  Yönetim panelinizden QR Menünüze kategori ve alt kategori
                  ekleyebilirsiniz.
                </li>
                <li>
                  Yönetim panelinizden QR Menünüze yeni ürün ekleyip istediğiniz
                  zaman saniyeler içerisinde görsel, fiyat, isim ve açıklamasını
                  düzenleyebilirsiniz.
                </li>
                <li>
                  Yönetim panelinizden QR Menünüzdeki ürünlerin açıklamalarını
                  ve isimlerini istediğiniz dile saniyeler içerisinde çevirisini
                  ekleyebilirsiniz.
                </li>
                <li>
                  Yönetim panelinizden QR Menünüzdeki ürünlerden dilediğinizi
                  saniyeler içerisinde kaldırabilirsiniz.
                </li>
                <li>
                  Yönetim panelinizden QR Menünüzden gelen garson çağır ve
                  adisyon iste bildirimlerini takip edebilirsiniz. Dilerseniz
                  panel üzerinden SMS yöntemini aktif ederek bildirimlerinizi
                  SMS ile alabilirsiniz.
                </li>
              </ul>

              <div className="mt-4 justify-center flex">
                <a
                  target="_blank"
                  href="https://qr-menu.adisyonjet.com/jetcoffee/table-1"
                  className="poppins-semibold text-[14px] text-white bg-primary p-4 rounded-[15px]"
                >
                  Yönetim Panelimizi İnceleyin
                </a>
              </div>
            </div>
          </div>

          <div>
            <img
              src="/cozumler/yonetim-paneli.webp"
              className="min-w-[300px] w-[800px] hidden lg:flex lg:flex-col"
              alt="yonetim-paneli"
            />
          </div>
        </div>

        <div className="container mx-auto flex flex-col lg:flex-row gap-x-8 mt-8 lg:mt-24 items-center">
          <div>
            <img
              src="/cozumler/masa-yonetimi.webp"
              className="lg:w-[600px] h-auto"
              alt="masa-yonetimi"
            />
          </div>
          <div>
            <h6 className="poppins-medium text-[#19191C] text-[28px] text-center lg:text-left">
              Salon ve Masa Yönetimi
            </h6>

            <div>
              <ul className="max-w-[700px] mt-4 poppins-regular text-black/80 space-y-4 list-disc list-inside">
                <li>
                  Yönetim paneli üzerinden dilediğiniz kadar salon ve masa
                  oluşturabilirsiniz.
                </li>
                <li>
                  Masaların durumlarını görüntüleyebilirsiniz. Masa durumları
                  Dolu ve Boş olarak görüntüleyebilirsiniz.
                </li>
                <li>
                  Müşterileriniz için açılan masaların açılış saatini
                  görüntüleyebilirsiniz.
                </li>
              </ul>

              <div className="mt-4 justify-center flex">
                <a
                  target="_blank"
                  href="https://qr-menu.adisyonjet.com/jetcoffee/table-1"
                  className="poppins-semibold text-[14px] text-white bg-primary p-4 rounded-[15px]"
                >
                  Yönetim Panelimizi İnceleyin
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="container ml-auto flex flex-col lg:flex-row gap-x-8 mt-8 lg:mt-24 items-center">
          <div>
            <div className="flex flex-col lg:hidden">
              <img
                src="/cozumler/siparis-yonetimi.webp"
                className="min-w-[400px] w-[400px]"
                alt="siparis-yonetimi"
              />
            </div>

            <h6 className="poppins-medium text-[#19191C] text-[28px] text-center lg:text-left">
              Sipariş ve Adisyon Yönetimi
            </h6>

            <div>
              <ul className="max-w-[700px] mt-4 poppins-regular text-black/80 space-y-4 list-disc list-inside">
                <li>
                  Yönetim panelinizden seçtiğiniz masanın adisyonunu
                  görüntüleyebilirsiniz.
                </li>
                <li>
                  Yönetim panelinizden seçtiğiniz masaya kolaylıkla ürün
                  ekleyebilir, çıkarabilir veya silebilirsiniz.
                </li>
                <li>
                  Yönetim panelinizden eklemek istediğiniz ürünün kategorisi ile
                  filtreleme yaparak çok kolay bir şekilde aradığınız ürüne
                  erişebilirsiniz.
                </li>
                <li>
                  Yönetim panelinizden müşterinin adisyonunu kapatabilirsiniz ve
                  ödeme yöntemini nakit veya kredi kartı olarak
                  işaretleyebilirsiniz.
                </li>
                <li>
                  Yönetim panelinizde ana sayfada son 24 saat'te aldığınız
                  ödemelerin nakit ve kredi kartı olarak sayısal değerini anlık
                  takip edebilirsiniz.
                </li>
              </ul>

              <div className="mt-4 justify-center flex">
                <a
                  target="_blank"
                  href="https://qr-menu.adisyonjet.com/jetcoffee/table-1"
                  className="poppins-semibold text-[14px] text-white bg-primary p-4 rounded-[15px]"
                >
                  Yönetim Panelimizi İnceleyin
                </a>
              </div>
            </div>
          </div>

          <div className="hidden lg:flex lg:flex-col">
            <img
              src="/cozumler/siparis-yonetimi.webp"
              className="min-w-[800px] w-[800px]"
              alt="siparis-yonetimi"
            />
          </div>
        </div>
      </div>

      <div className="mt-20 w-full bg-primary py-16 text-center">
        <h2 className=" poppins-bold text-white text-[48px] lg:text-[62px] ">
          Aklınıza birşey mi takıldı ?
        </h2>
        <p className="poppins-regular text-white/80 text-[18px] lg:text-[24px] mt-2 ">
          Aklınıza takılan sorular için iletişim formumuzu doldurarak satış
          ekibimiz tarafından bilgi alabilirsiniz.
        </p>

        <button className="poppins-semibold text-[14px] text-white border-white border-2 p-4 rounded-[15px] mt-7">
          Hemen Bilgi Al
        </button>
      </div>

      <div>
        <ContactBlock />
      </div>

      <div>
        <Footer />
      </div>
    </div>
  );
}

export default Home;
